
function initTestimonialsCarousel() {
	$('.testimonials-carousel').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		autoplay: true,
		autoplaySpeed: 5000,
		prevArrow: '<svg width="21px" height="34px" viewBox="0 0 21 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="slider-prev-arrow slick-prev"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1452.000000, -76.000000)" stroke="#D1B19B" stroke-width="3"><polyline  transform="translate(1462.000000, 93.000000) translate(-1462.000000, -93.000000) " points="1470 77 1454 93 1470 109"></polyline></g></g></svg>',
		nextArrow: '<svg width="21px" height="34px" viewBox="0 0 21 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="slider-next-arrow slick-next"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1452.000000, -76.000000)" stroke="#D1B19B" stroke-width="3"><polyline  transform="translate(1462.000000, 93.000000) scale(-1, 1) translate(-1462.000000, -93.000000) " points="1470 77 1454 93 1470 109"></polyline></g></g></svg>',
	});
}

function initHeaderCarousel() {
	$('.header-carousel').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		pauseOnHover: false,
		autoplay: true,
		autoplaySpeed: 5000,
		prevArrow: '<svg width="21px" height="34px" viewBox="0 0 21 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="slider-prev-arrow slick-prev"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1452.000000, -76.000000)" stroke="#D1B19B" stroke-width="3"><polyline  transform="translate(1462.000000, 93.000000) translate(-1462.000000, -93.000000) " points="1470 77 1454 93 1470 109"></polyline></g></g></svg>',
		nextArrow: '<svg width="21px" height="34px" viewBox="0 0 21 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="slider-next-arrow slick-next"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1452.000000, -76.000000)" stroke="#D1B19B" stroke-width="3"><polyline  transform="translate(1462.000000, 93.000000) scale(-1, 1) translate(-1462.000000, -93.000000) " points="1470 77 1454 93 1470 109"></polyline></g></g></svg>',
	});
}

function initFeaturedCarousel(){
	$('.featured-block-media-items').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		autoplay: true,
		autoplaySpeed: 5000,
		prevArrow: '<svg width="21px" height="34px" viewBox="0 0 21 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="slider-prev-arrow slick-prev"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1452.000000, -76.000000)" stroke="#D1B19B" stroke-width="3"><polyline  transform="translate(1462.000000, 93.000000) translate(-1462.000000, -93.000000) " points="1470 77 1454 93 1470 109"></polyline></g></g></svg>',
		nextArrow: '<svg width="21px" height="34px" viewBox="0 0 21 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="slider-next-arrow slick-next"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1452.000000, -76.000000)" stroke="#D1B19B" stroke-width="3"><polyline  transform="translate(1462.000000, 93.000000) scale(-1, 1) translate(-1462.000000, -93.000000) " points="1470 77 1454 93 1470 109"></polyline></g></g></svg>',
	});
}

function initInstagramFeed(){
	$('#instagram-feed').slick({
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		autoplay: true,
		autoplaySpeed: 3000,
		prevArrow: '<img src="/assets/images/instagram-arrow-left.png" alt="Left Arrow" class="slider-prev-arrow slick-prev">',
		nextArrow: '<img src="/assets/images/instagram-arrow-right.png" alt="Right Arrow" class="slider-next-arrow slick-next">',
		responsive: [
			{
				breakpoint: 1700,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 1450,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					centerMode: true,
					arrows: false
				}
			},
		]
	});
}


$(document).ready(function () {
	if ($('.testimonials-carousel').length) {
		initTestimonialsCarousel();
	}
	if ($('.header-carousel').length) {
		initHeaderCarousel();
	}
	if ($('.featured-block-media-items').length) {
		if($(window).width() < 768){
			initFeaturedCarousel();
		}
	}
	if ($('#instagram-feed').length) {
		setTimeout(function(){
			initInstagramFeed();
		}, 500);

	}
});
