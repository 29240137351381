function buttonSlideEvent() {
	$('.btn-slide').click(function () {
		$(this).toggleClass('open');
		$(this).siblings('.btn-slide-item').slideToggle(350, function () {
			$(this).toggleClass('open');
		});
	});
}


function mobileMenu(){
 $('.menu-toggle').click(function(){
	 $('.header-nav .main-menu').slideToggle(350);
	 $('.header-nav').toggleClass('open');
 });
 $('.sub-menu-parent').click(function(){
	 if($(window).width() < 1300){
		 $(this).toggleClass('open');
		 $(this).children('.sub-menu').slideToggle();
	 }
 });
}

function videoHeaderPlay(){
	if($('.video-container').hasClass('video-paused')){
		$('.video-header').get(0).play();
	}		
}

function videoHeaderStop(){
	$('.video-header-link').click(function(){
		$('.video-container').addClass('video-paused');
		$('.video-header').get(0).pause();
	});
}

$(document).ready(function () {
	if ($('.btn-slide').length) {
		buttonSlideEvent();
	}
	if($('.video-header').length){
		videoHeaderStop();
	}
	mobileMenu();
});
